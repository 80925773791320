<template>
  <b-modal
    ref="password-modal"
    no-close-on-backdrop
    hide-footer
    title="เปลี่ยนรหัสผ่าน"
    ok-variant="success"
    ok-title="เปลี่ยนรหัสผ่าน"
    cancel-title="ยกเลิก"
    footer-class="justify-content-between"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="ชื่อผู้ใช้"
      >
        <b-form-input
          v-model="input.username"
          type="text"
          disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="รหัสผ่านใหม่"
        label-for="input-new-password"
      >
        <b-form-input
          id="input-new-password"
          v-model="input.newPassword"
          type="password"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="ยืนยันรหัสผ่าน"
        label-for="input-confirm-password"
      >
        <b-form-input
          id="input-confirm-password"
          v-model="input.confirmPassword"
          type="password"
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="danger" block pill :disabled="isProcess">{{isProcess ? 'กำลังบันทึกรหัสผ่าน...' : 'เปลี่ยนรหัสผ่าน'}}</b-button>

    </form>
  </b-modal>
</template>
<script>
import AccountService from "@/services/accountService"
import Swal from 'sweetalert2'

export default {
  name: 'ChangePasswordModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: {
        _id: null,
        username: null,
        newPassword: null,
        confirmPassword: null
      }
    }
  },
  data() {
    return {
      isProcess: false,
      input: JSON.parse(JSON.stringify(this.data))
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = JSON.parse(JSON.stringify(this.data))
    }
  },
  methods: {
    resetModal() {
      this.isProcess = false
      this.input = {
        _id: null,
        username: null,
        newPassword: null,
        confirmPassword: null
      }
    },
    showModal() {
      this.$refs['password-modal'].show()
    },
    hideModal() {
      this.$refs['password-modal'].hide()
    },
    handleSubmit() {

      if(this.input.newPassword !== this.input.confirmPassword) {
        Swal.fire({
          text: 'ยืนยันรหัสผ่านไม่ถูกต้อง',
          icon: 'error',
          confirmButtonText: 'OK'
        }).then((res)=>{
          this.input.confirmPassword = ''
        })
        return
      }

      const self = this
      this.isProcess = true
      AccountService.changePassword(this.data._id, {
        newPassword: this.input.newPassword
      })
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'เปลี่ยนรหัสผ่านเสร็จเรียบร้อย',
          })
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: 'ผิดพลาด!',
          text: e?.data || 'เปลี่ยนรหัสผ่านไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  }
}
</script>
