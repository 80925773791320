<template>
  <div class="mb-2">
    <table class="table table-sm table-bordered">
      <thead>
        <tr>
          <th :colspan="marketOpenBets.length + 3" class="text-center"><h4 class="text-success mb-0">{{ groupName(data.groupId) }}</h4></th>
        </tr>
        <tr class="bg-light text-dark">
          <th width="20%" class="text-center">อัตราจ่าย</th>
          <th width="10%" class="text-center">เปิด/ปิด</th>
          <th width="10%" class="text-center"></th>
          <th class="text-center" v-for="bet in marketOpenBets" :key="bet.code">{{ bet.text }}</th>
        </tr>
      </thead>
      <tbody v-for="rate in payrates" :key="rate.rateId">
        <tr>
          <td rowspan="2" class="text-center">{{ rateName(rate.rateId) }}</td>
          <td rowspan="2" class="text-center">
            <b-form-checkbox v-model="rate.isAvailable" name="check-button" :class="[rate.isAvailable ? 'text-success' : 'text-danger']" switch> <span>{{ rate.isAvailable ? 'เปิด' : 'ปิด' }}</span> </b-form-checkbox>
          </td>
          <td class="text-center">จ่าย</td>
          <td class="text-center" v-for="bet in marketOpenBets" :key="bet.code">
            <b-form-input v-if="rate.rates[bet.code].isAvailable" type="number" :number="true" size="sm" class="text-center" v-model="rate.rates[bet.code].pay" :disabled="!rate.isAvailable"></b-form-input>
            <span v-else class="text-danger">ปิด</span>
          </td>
        </tr>
        <tr>
          <td class="text-center">ส่วนลด (%)</td>
          <td class="text-center" v-for="bet in marketOpenBets" :key="bet.code">
            <b-form-input v-if="rate.rates[bet.code].isAvailable" type="number" :number="true" size="sm" class="text-center" v-model="rate.rates[bet.code].discount" :disabled="!rate.isAvailable || !rate.rates[bet.code].isAvailable"></b-form-input>
            <span v-else class="text-danger">ปิด</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {marketOpenBets} from '@/configs/market.config'

export default {
  name: 'TabModalRateTable.vue',
  props: ['data'],
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    payrates() {
      return this.data.payRate
    }
  },
  methods: {
    groupName(groupId) {
      let group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId
      })

      if(group) {
        return group.group_title
      }else{
        return groupId
      }
    },
    rateName(rateId) {
      let rate = this.$store.state.marketPayrates.find((r)=>{
        return r._id === rateId
      })

      if(rate) {
        return rate.rateTitle
      }else{
        return rateId
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  td {
    vertical-align: middle;
  }
}
</style>
