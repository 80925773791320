<template>
  <div class="py-2 mt-3">
    <div class="mb-1 btn-group">
      <button
        v-for="group in data"
        :key="group.groupId"
        v-if="group.isAvailable && isGroupActive(group.groupId)"
        type="button"
        class="btn"
        :class="[selectGroup.groupId===group.groupId ? 'btn-primary' : 'btn-light']"
        @click="selectGroup=group">{{ groupName(group.groupId) }}</button>
    </div>
    <TabModalRateTable v-if="selectGroup.groupId" :data="selectGroup" />
  </div>
</template>
<script>
import TabModalRateTable from './TabModalRateTable'

export default {
  name: 'TabModalRate',
  components: {
    TabModalRateTable
  },
  props: ['data'],
  data() {
    return {
      selectGroup: {
        groupId: null
      }
    }
  },
  methods: {
    groupName(groupId) {
      const group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId
      })

      if(group) {
        return group.groupTitle
      }else{
        return groupId
      }
    },
    isGroupActive(groupId) {
      const group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId
      })

      return group?.status === 'Active' && group?.groupType === 'single'
    }
  },
  mounted() {
    let selectGroup = null
    for (const groupId in this.data) {
      if(this.data[groupId]?.isAvailable) {
        selectGroup = this.data[groupId]
        break;
      }
    }

    if(selectGroup) {
      this.selectGroup = selectGroup
    }
  }
}
</script>
