<template>
  <b-modal
    ref="account-company-modal"
    hide-footer
    size="xl"
    :title="modalTitle"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
      v-if="input"
    >
      <div class="row">
        <div class="col-md-4">
          <b-form-group
            class="form-username"
            label="ชื่อผู้ใช้ *"
            description="ความยาว 4-20 ตัวอักษร ภาษาอังกฤษ a-Z หรือตัวเลข 0-9 เท่านั้น"
            :state="invalidKeys.username.status"
            :invalid-feedback="invalidKeys.username.text"
          >
            <b-form-input
              name="username"
              v-model="input.user.username"
              type="text"
              placeholder="ชื่อผู้ใช้"
              required
              :state="invalidKeys.username.status"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            class="form-password"
            label="รหัสผ่าน *"
            description="รหัสผ่านอย่างน้อย 6 ตัวอักษร"
            :state="invalidKeys.password.status"
            :invalid-feedback="invalidKeys.password.text"
          >
            <b-form-input
              name="password"
              v-model="input.user.password"
              type="password"
              placeholder="รหัสผ่าน"
              minLength="6"
              required
              :state="invalidKeys.password.status"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            class="form-level"
            label="ระดับเอเย่นต์ *"
            :state="invalidKeys.levelId.status"
            :invalid-feedback="invalidKeys.levelId.text"
          >
            <b-form-select
              name="levelId"
              v-model="input.account.levelId"
              :options="levelOptions"

              :state="invalidKeys.levelId.status"
            ></b-form-select>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <b-form-group
            class="form-firstname"
            label="ชื่อ *"
            :state="invalidKeys.firstName.status"
            :invalid-feedback="invalidKeys.firstName.text"
          >
            <b-form-input
              name="firstName"
              v-model="input.user.firstName"
              type="text"
              placeholder="ชื่อ"

              :state="invalidKeys.firstName.status"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            label="นามสกุล"
          >
            <b-form-input
              name="lastName"
              v-model="input.user.lastName"
              type="text"
              placeholder="นามสกุล"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            label="โทรศัพท์"
          >
            <b-form-input
              name="tel"
              v-model="input.user.tel"
              type="text"
              placeholder="โทรศัพท์"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <b-form-group
            label="Line ID"
          >
            <b-form-input
              name="lineId"
              v-model="input.user.lineId"
              type="text"
              placeholder="Line ID"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="mt-3">
        <b-nav tabs>
          <b-nav-item class="tab-group" :active="tabActive==='group'" @click="tabActive='group'">เปิด/ปิด กลุ่มหวย</b-nav-item>
          <b-nav-item class="tab-share" :active="tabActive==='share'" @click="tabActive='share'">แบ่งหุ้น/เก็บของ</b-nav-item>
          <b-nav-item class="tab-limit" :active="tabActive==='limit'" @click="tabActive='limit'">ข้อมูลการแทง</b-nav-item>
          <b-nav-item class="tab-rate" :active="tabActive==='rate'" @click="tabActive='rate'">อัตราจ่าย</b-nav-item>
        </b-nav>

        <TabModalGroup :data="input.account.groups" v-if="tabActive==='group'" />
        <TabModalShare :data="input.account.groups" v-if="tabActive==='share'" />
        <TabModalLimit :data="input.account.groups" v-if="tabActive==='limit'" />
        <TabModalRate :data="input.account.groups" v-if="tabActive==='rate'" />
      </div>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : modalTitle}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import AccountService from "@/services/accountService"

import TabModalGroup from './TabModalGroup'
import TabModalShare from './TabModalShare'
import TabModalLimit from './TabModalLimit'
import TabModalRate from './TabModalRate'

import Swal from 'sweetalert2'

const invalidKeys = {
  username: {
    status: null,
    text: null
  },
  password: {
    status: null,
    text: null
  },
  levelId: {
    status: null,
    text: null
  },
  firstName: {
    status: null,
    text: null
  }
}

export default {
  name: 'AccountCompanyModal',
  components: {
    TabModalGroup,
    TabModalShare,
    TabModalLimit,
    TabModalRate
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    levels: {
      type: Array,
      default: []
    },
    data: {
      type: [Object, String],
      default: null
    }
  },
  data() {
    return {
      isProcess: false,
      needReload: false,
      input: null,
      tabActive: 'group',
      invalidKeys: JSON.parse(JSON.stringify(invalidKeys))
    }
  },
  computed: {
    modalTitle() {
      if(this.input) {
        return this.input.account._id ? 'แก้ไขบัญชีบริษัท' : 'เพิ่มบัญชีบริษัท'
      }else{
        return '-'
      }
    },
    levelOptions() {
      let nullOption = [{text: '--- เลือกระดับเอเย่นต์ ---', value: null}]
      let levelOptions = this.levels.map((level)=>{
        return {
          text: level.levelName,
          value: level._id
        }
      })
      return nullOption.concat(levelOptions)
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    // data() {
    //   if(this.data) {
    //     this.input = JSON.parse(JSON.stringify(this.data))
    //   }
    // }
  },
  methods: {
    updateGroups(groups) {
      console.log('groups', groups)
      this.input.account.groups = groups
    },
    resetModal() {
      this.needReload = false

      this.invalidKeys = JSON.parse(JSON.stringify(invalidKeys))
      if(this.data) {
        this.input = JSON.parse(JSON.stringify(this.data))
      }
    },
    showModal() {
      this.$refs['account-company-modal'].show()
    },
    hideModal() {
      this.$refs['account-company-modal'].hide()
    },
    handleSubmit() {
      delete this.input.user._id
      delete this.input.user.accountId
      AccountService.saveCompanyAccount(this.input)
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'สร้างบัญชีบริษัทเสร็จเรียบร้อย',
          })
        }else{
          throw response
        }
      })
      .catch((e)=>{
        console.log(e)
        if(e.success === false) {
          if(e?.errors) {
            e.errors.forEach((error)=>{
              this.findError(error)
            })
          }
        }
        Swal.fire({
          title: 'ผิดพลาด!',
          text: 'สร้างบัญชีบริษัทไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    findError(error) {
      if(error?.source?.children) {
        error.source?.children.forEach((error)=>{
          this.findError(error)
        })
      }else
      if(error?.parameter){
        this.invalidKeys[error.parameter] = {
          status: false,
          text: error?.detail
        }
      }else
      if(error?.source){
        this.invalidKeys[error.source.parameter] = {
          status: false,
          text: error?.detail
        }
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
