<template>
  <div class="py-2 mt-3">
    <table class="table-open-close table table-sm">
      <thead>
        <tr>
          <th width="150">เปิด/ปิด</th>
          <th>กลุ่มหวย</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="group in data" v-if="isGroupActive(group.groupId)">
          <td class="text-left">
            <b-form-checkbox v-model="group.isAvailable" name="check-button" :class="[group.isAvailable ? 'text-success' : 'text-danger']" switch> <span>{{ group.isAvailable ? 'เปิด' : 'ปิด' }}</span> </b-form-checkbox>
          </td>
          <td>{{ groupName(group.groupId) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'TabModalGroup',
  props: ['data'],
  methods: {
    groupName(groupId) {
      const group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId
      })

      if(group) {
        return group.groupTitle
      }else{
        return groupId
      }
    },
    isGroupActive(groupId) {
      const group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId
      })

      return group?.status === 'Active' && group?.groupType === 'single'
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  th {
    border-top: 0;
  }
  td {
    vertical-align: middle;
  }
}
</style>
